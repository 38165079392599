import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import { Button, HeaderPlaceholder, Underline } from "../../styles/ui.styles";
import SubscribeForm from "../../components/subscribeForm";
import { StaticImage } from "gatsby-plugin-image";

import { FaCheck } from "react-icons/fa";
import Configurator from "../../components/configurator/configurator";
import useScrollTo from "../../lib/scrollto";
import ExpandableItem from "../../components/expandableItem";

const DataBay: React.FC = () => {
  const { scrollTo } = useScrollTo();
  return (
    <>
      <SEO title={"DataBay"} description={"DataBay"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <FlexSection>
            <div>
              <h1>DataBay</h1>
              <p>
                To be the fastest on a racecourse you need training and
                information about the performance of your boat. You do the
                training, and we help with useful insights based on a huge
                amount of input data that we collected through the onboard
                Fastrrr systems helping you to achieve the optimal sailing
                performance in all conditions. With the affordable Fastrrr
                system you don’t need the same budget as those who compete in
                the America’s Cup or in the Volvo Ocean Race to get the same
                benefits.
              </p>
              <Buttons>
                <Button
                  onClick={() => scrollTo("#configurator", -100)}
                  primary={true}
                >
                  CONFIGURE
                </Button>
              </Buttons>
            </div>
            <div>
              <StaticImage
                style={{ width: "100%", height: "100%" }}
                src={"../../images/data_bay.jpg"}
                alt={"DataBay"}
                objectFit={"contain"}
              />
            </div>
          </FlexSection>
          <Title id="why">
            <h2>Why DataBay?</h2>
            <Underline />
          </Title>

          <Whysection>
            <p>
              We have created a user-friendly interface design which makes the
              analysis clear and understandable for all experience levels of
              sailors and coaches. With our variety of products, the system can
              be applied from the smallest dinghy to the biggest sailboat.
              Fastrrr DataBay is a user-friendly online data analytics
              interface, where you can easily track your training progress,
              review your sessions, save your boat settings, and analyze the
              optimal sailing performance for continuous improvement and the
              perfect sailing experience.
            </p>
          </Whysection>

          <Title>
            <h2>Topology</h2>
            <Underline />
          </Title>

          <div style={{ width: "100%" }}>
            <StaticImage
              style={{ width: "100%" }}
              src={"../../images/dinghy_graph.png"}
              alt={"DinghyEdition Graph"}
              objectFit={"contain"}
            />
          </div>

          <Title>
            <h2>Features</h2>
            <Underline />
          </Title>

          <FeaturesGrid>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Online platform
                  </StyledListElement>
                  <p>
                    Data is collected in an online platform which is tailored to
                    the needs of both leisure and professional sailors. This
                    data is then used to provide prompt advice for sailors and
                    crew on how to optimize performance.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Achievements
                  </StyledListElement>
                  <p>
                    Complete challenging tasks and compete with your fellow
                    sailors.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Targets table
                  </StyledListElement>
                  <p>
                    No need to write reminder notes on the deck. Your boat
                    settings can be stored in one place, complemented by your
                    sailing performance data to ensure your progress.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Weather forecast
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Overall performance
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Polar heatmap / diagram
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Sail chart
                  </StyledListElement>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Access for the whole crew
                  </StyledListElement>
                  <p>
                    We give you instant access to data for your whole sailing
                    team. The session viewer allows for notes taken by the coach
                    to be viewed by the sailors, even if they are on a different
                    continent.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Accuracy
                  </StyledListElement>
                  <p>
                    Fastrrr&apos;s cutting edge - industrial grade - GPS and
                    sensor fusion technology gives outstanding accuracy.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Sailing logbook
                  </StyledListElement>
                  <p>Enjoy the passage, DataBay handles all the paperwork.</p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Real time analysis
                  </StyledListElement>
                  <p>
                    By using Fastrrr, you not only get the momentarily data, but
                    with real time analysis you get the optimal targets for the
                    current conditions and the settings needed for maximal
                    performance.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Cloud system
                  </StyledListElement>
                  <p>
                    Never lose a sail or boat setting, store everything in the
                    cloud.
                  </p>
                </li>
              </ul>
            </div>
          </FeaturesGrid>
          {/*
          <Title>
            <h2>More details</h2>
            <Underline />
          </Title>
         <ExpandableGrid>
            <ExpandableItem
              title={"What’s in the box?"}
              expandableText={"Ask detective David Mills"}
            />
            <ExpandableItem
              title={"Features"}
              expandableText={"You can sail with it m8"}
            />
            <ExpandableItem
              title={"Technical specifications"}
              expandableText={
                "Look at the support section to know more about this awesome product lorem ipsum"
              }
            />
            <ExpandableItem
              title={"Shipping details"}
              expandableText={"Shipping more like sailing amirite badum tss🥁"}
            />
          </ExpandableGrid>*/}
        </Wrapper>
        <div id={"configurator"} />
        <Configurator />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default DataBay;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;

  p {
    color: var(--color-dark-grey);
  }
`;

const Whysection = styled.section`
  columns: 400px 2;
  column-gap: 32px;
  p {
    margin-top: 0;
  }
`;

const FlexSection = styled.section`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  div {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Title = styled.div`
  margin: 70px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    margin-top: 32px;
  }
  div {
    margin: 32px 0;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
    background: #f3f3f3;
    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
  svg {
    min-width: 16px;
  }
`;

const ExpandableGrid = styled.div`
  p {
    color: unset;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
